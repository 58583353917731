<template>
  <img :src="tag" alt="SVG Icon" />
</template>

<script setup lang="ts">
import { camelCase } from 'lodash';
import { computed, onMounted } from 'vue';

import { SvgNames, TAG_MAPPING } from './svgImports';

const props = defineProps<{ svg: string }>();

onMounted(() => {
  if (!TAG_MAPPING[camelCase(props.svg) as SvgNames]) {
    console.warn(`SVG ${props.svg} not found`);
  }
});

const tag = computed(() => TAG_MAPPING[camelCase(props.svg) as SvgNames]);
</script>
